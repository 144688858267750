import React, { FunctionComponent } from "react"

interface WindowIconProps {
  size?: string
}

const WindowIcon: FunctionComponent<WindowIconProps> = ({ size = "24" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3H3V10H10V3Z"
        fill="#6633FF"
        stroke="#6633FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 3H14V10H21V3Z"
        fill="#A385FF"
        stroke="#A385FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 14H14V21H21V14Z"
        fill="#A385FF"
        stroke="#A385FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 14H3V21H10V14Z"
        fill="#A385FF"
        stroke="#A385FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default WindowIcon
