import Seo from "../components/seo"
import React, { useEffect, useState, useRef, FunctionComponent } from "react"
import WigglyLines from "../assets/wigglyLine"
import HeroScribble from "../assets/heroScribble"

import { generateRandomNumber } from "../utils/generateRandomNumber"
import { MonitorIcon } from "../assets/monitorIcon"
import { ArggIcon, CrossIcon, ShareIcon } from "../assets/modalIcons"
import { initAmplitude, sendAmplitudeData } from "../utils/amplitude"
import { AmplitudeEvents } from "../types/amplitudeEvents"
import {
  handleMouseDownEvent,
  handleMouseUpEvent,
} from "../utils/buttonClickScaleEffect"
import "./common.css"

import Footer from "../components/Footer"
import LogoHeader from "../components/LogoHeader"
import "./index.css"
import "./typography.css"
import "./media.css"
import { getPSTTime } from "../utils/getPSTTime"
import {
  DESCRIPTION_METADATA,
  limitedTimeDealEndDate,
  TITLE_METADATA,
} from "../utils/constants"
import Testimonials from "../components/Testimonials"
import RemoteTeams from "../components/RemoteTeams"
import FloatingDock from "../components/FloatingDock"
import MixersSection from "../components/MixersSection"

import FreeMixerModal from "../components/FreeMixerModal"
import PaymentSection from "../components/PaymentSection"
import TemplatesSection from "../components/TemplatesSection"
import KytIconWhite from "../assets/KytIconWhite"
import { utmCookieRequest } from "../utils/handleUTFTracking"
import FaqSection from "../components/FaqSection"
import FeaturesVertical from "../components/FeaturesVertical"
import {
  landingPageFeatureVertical,
  landingPageHeroSectionImages,
  landingPageMugshotList,
} from "../constants/landingPage"
import CatchPhraseSection from "../components/CatchPhraseSection"
import MugshotCollection from "../components/MugshotCollection"
import AdvantagesSection from "../components/AdvantagesSection"
import TwitterWall from "../components/TwitterWall"
import OnlyOnDesktop from "../components/OnlyOnDesktopSection"
import FloatingBannerContainer from "../components/FloatingBannerContainer"
import CountdownTimer from "../components/CountdownTimer"
import { isJune9th } from "../utils/isBefore28thOctober"
import { handleProductHuntDSRedirect } from "../utils/handleProducthuntRedirect"
import PLGBranding from "../components/PLGBranding"

interface OpacityHandlerInterface {
  highestOpacityTxtIndex: number
  allElements: ChildNode[] | any
}
export const handleContactUs = () => {
  window.location.href = process.env.CONTACT_US_LINK
}

const IndexPage: FunctionComponent = () => {
  const [catchPhraseIndex, setCatchPhraseIndex] = useState<number>(0)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [showWI, setShowWI] = React.useState(false)
  const [showFreeMixerModal, setShowFreeMixerModal] = React.useState(false)
  const [templateNumber, setTemplateNumber] = React.useState(0)
  const [isJSLoaded, setIsJSLoaded] = useState(true)
  const [ltdTimer, setLtdTimer] = useState([])
  const [showSuperDeal, setSuperDeal] = useState(false)

  const startTimer = () => {
    const setTimer = setInterval(function () {
      const now = new Date().getTime()
      const distance = limitedTimeDealEndDate - now

      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (days <= 0 && hours <= 0 && minutes <= 0) {
        setSuperDeal(false)
        clearInterval(setTimer)
      }
      let currentTime = []
      currentTime.push(days, hours, minutes, seconds)
      setLtdTimer(currentTime)
    }, 1000)
  }

  React.useEffect(() => {
    const now = new Date().getTime()
    const distance = limitedTimeDealEndDate - now
    if (distance > 0) {
      setSuperDeal(true)
      startTimer()
    }
  }, [])

  const days = [
    "friday",
    "saturday",
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
  ]

  const freeSocialHandler = () => {
    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      setIsModalVisible(true)
    } else {
      setShowFreeMixerModal(true)
    }
  }

  const handleCreateSocial = btnType => () => {
    const thursdayLink = process.env.THURSDAY_SIGNIN_URL
    const jointhursdayLink = `${thursdayLink}?auto=true&templateCode=aba81eeb-6275-4b2f-be80-3fee8082acf5`
    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
      setIsModalVisible(true)
    } else {
      sendAmplitudeData(btnType)
      // window?.open(thursdayLink, "_self")
      window.location.href = jointhursdayLink
    }
  }
  const handleJoinDashboard = () => {
    const dashboardLink = process.env.THURSDAY_SIGNIN_URL
    const joinDashboardLink = `${dashboardLink}?auto=true&templateCode=aba81eeb-6275-4b2f-be80-3fee8082acf5`
    sendAmplitudeData(AmplitudeEvents.HANDLE_DASHBOARDPAGE_FROM_FEATUREVERTICAL)
    window.location.href = joinDashboardLink
  }
  const handleJoinSocial = () => {
    const joinSocialLink = process.env.THURSDAY_SIGNIN_URL
    const joinLink = `${joinSocialLink}?auto=true&templateCode=aba81eeb-6275-4b2f-be80-3fee8082acf5`
    sendAmplitudeData(AmplitudeEvents.HANDLE_JOINPAGE_FROM_FEATUREVERTICAL)
    window.location.href = joinLink
  }

  const handleSignIn = btnType => () => {
    const thursdayLink = process.env.THURSDAY_SIGNIN_URL

    if (window?.innerWidth < 800) {
      // The body scroll should be disable when the modal is open
      const body = document.querySelector("body")
      body.style.overflow = "hidden"
      sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
      setIsModalVisible(true)
    } else {
      sendAmplitudeData(btnType)
      // window?.open(thursdayLink, "_self")
      window.location.href = thursdayLink
    }
  }

  useEffect(() => {
    setIsJSLoaded(false)
    utmCookieRequest()
  }, [])

  useEffect(() => {
    const vidEle: any = document.getElementsByClassName("hero-video")[0]

    /* Reset video container height when the video is resized */
    const resetVideoContainerHeight = () => {
      document.getElementsByClassName("floating-video")[0].style.minHeight = `${
        vidEle?.getBoundingClientRect()?.height + 5
      }px`
    }

    /* When the video is loaded reset the video container height.
     To avoid shift of the other content when the next videos are loaded  */
    const handleVideoLoaded = () => {
      if (vidEle?.readyState >= 3) {
        vidEle?.play()
        resetVideoContainerHeight()
      }
    }

    vidEle.addEventListener("loadeddata", handleVideoLoaded)
    window?.addEventListener("resize", resetVideoContainerHeight)

    return () => {
      vidEle.removeEventListener("loadeddata", handleVideoLoaded)
      window?.removeEventListener("scroll", resetVideoContainerHeight)
    }
  }, [])

  useEffect(() => {
    const randomNumber = generateRandomNumber({ max: 5, min: 0 })
    const isParamRef = new URLSearchParams(window?.location?.search || "").get(
      "ref"
    )
    const extraParams: { "ref-url"?: string } = {}
    const currentPstTime = getPSTTime([])

    const day = currentPstTime?.getDate()
    const month = currentPstTime?.getMonth()

    const isRedirectedFromPH = isParamRef === "producthunt"
    const isOctober = month === 9 && day > 27
    const isNovember = month === 10 && day < 3

    const checkIfWithinLaunchWeek = isNovember || isOctober

    if (document && document.referrer) {
      extraParams["ref-url"] = document.referrer
    }

    setCatchPhraseIndex(randomNumber)

    initAmplitude()
    sendAmplitudeData(AmplitudeEvents.MARKETING_PAGE_VISITED, extraParams)

    if (isRedirectedFromPH || checkIfWithinLaunchWeek) {
      setShowBanner(true)
    }

    if (isParamRef === "producthunt") {
      sendAmplitudeData(AmplitudeEvents.REDIRECT_FROM_PRODUCTHUNT)
    }
  }, [])

  const showImageForIndex = imageIndex => {
    setSelectedImageIndex(imageIndex)

    // If window size is less we show a scrollbar for the video options
    // The selected option should scroll to the left automatically
    if (window.innerWidth <= 550) {
      scrollImgOptions(imageIndex)
    }
  }

  const showNextImage = () => {
    showImageForIndex(prevIn =>
      prevIn === landingPageHeroSectionImages.length - 1 ? 0 : prevIn + 1
    )
  }

  useEffect(() => {
    setInterval(showNextImage, 6000)
  }, [])

  const scrollImgOptions = optionIndex => {
    const scrollSection = document.getElementsByClassName(
      "floating-video-options"
    )[0]
    // Calculate difference between left of the options container & the current selected option
    // Scroll the options left by the new calculated value
    const leftScrollLen =
      scrollSection?.children?.[optionIndex]?.getBoundingClientRect()?.left -
      scrollSection?.getBoundingClientRect()?.left

    scrollSection.scrollLeft = leftScrollLen
  }

  const closeModal = () => {
    // The body scroll should be disable when the modal is open
    const body = document.querySelector("body")
    body.style.overflow = "auto"

    setIsModalVisible(false)
  }

  const handleEventBubbling = ev => {
    ev.stopPropagation()
  }

  // Move to utils
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: TITLE_METADATA,
          text: DESCRIPTION_METADATA,
          url: window?.location?.href,
        })
        .then(() => console.log("Successful share"))
        .catch(error => console.log("Error sharing the link", error))
    } else {
      console.log("Share not supported on this browser, do it the old way.")
    }
  }

  return (
    <>
      <Seo title={TITLE_METADATA} canonical="https://thursday.social/" />
      <FloatingDock setIsModalVisible={setIsModalVisible} />
      {isModalVisible && (
        <div className="modal-bg" onClick={closeModal}>
          <div className="modal" onClick={handleEventBubbling}>
            <div className="cross" onClick={closeModal}>
              <CrossIcon />
            </div>
            <div className="argg-icon">
              <ArggIcon />
            </div>

            <p className="user-msg">
              Thursday is available only on desktop, for now
            </p>

            <p className="create-social-msg">Create a social from desktop</p>

            <button
              className="share-btn"
              onClick={handleShare}
              onMouseDown={handleMouseDownEvent}
              onMouseUp={handleMouseUpEvent}
            >
              <ShareIcon />
              &nbsp; Share
            </button>
          </div>
        </div>
      )}

      <div>
        <div className="hero-section purple" id="home-section">
          <LogoHeader headerData={days} logo="thursdayLogo" />

          <div className="h1Text hero-text">
            where remote teams{" "}
            <MugshotCollection mugshots={landingPageMugshotList} />
            build strong&nbsp;
            <span className="text-with-underline salt-txt ">
              <span className="underlineAnimation-activate-onHover">
                culture
              </span>

              <div className="underlineAnimation-container">
                <div className="wigglyLines-container">
                  <WigglyLines color="var(--theme-white)" />
                </div>
              </div>
            </span>
          </div>

          <div className="hero-subText h3Text">
            Connect over engaging social activities.
            <br /> Fun times are just 1-click away.
          </div>

          <OnlyOnDesktop />

          <div className="hero-btn-container">
            {isJSLoaded ? (
              <a
                onClick={handleSignIn(
                  AmplitudeEvents.CREATE_SOCIAL_FROM_HERO_BUTTON
                )}
                className="theme-btn  btn-purpleTxt"
                href={process.env.THURSDAY_SIGNIN_URL}
              >
                Get started now
              </a>
            ) : (
              <button
                onClick={handleSignIn(
                  AmplitudeEvents.CREATE_SOCIAL_FROM_HERO_BUTTON
                )}
                className="theme-btn  btn-purpleTxt"
                onMouseDown={handleMouseDownEvent}
                onMouseUp={handleMouseUpEvent}
              >
                Get started now
              </button>
            )}

            <HeroScribble />
          </div>
        </div>

        <div className="floating-video-container">
          <div className="floating-video-main">
            <div className="options-container">
              <div className="floating-video-options">
                {landingPageHeroSectionImages.map((val, ind) => {
                  return (
                    <div
                      className={`floating-video-option ${
                        selectedImageIndex === ind ? "active" : ""
                      }`}
                      key={ind + val.name}
                      onClick={() => showImageForIndex(ind)}
                    >
                      {val.icon}
                      {val.name}{" "}
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="floating-video">
              {landingPageHeroSectionImages.map((val, ind) => (
                <img
                  key={ind + val.name + "image"}
                  src={val.src}
                  style={{
                    display: ind === selectedImageIndex ? "block" : "none",
                  }}
                  className="hero-video"
                />
              ))}
            </div>
          </div>
        </div>

        <RemoteTeams showGoldenKitty />

        <FeaturesVertical
          content={landingPageFeatureVertical}
          onClickJoinDashboard={handleJoinDashboard}
          onClickJoinSocial={handleJoinSocial}
        />

        <FreeMixerModal
          show={showFreeMixerModal}
          setShow={setShowFreeMixerModal}
          handleSignIn={handleSignIn}
          handleCreateSocial={handleCreateSocial}
        />

        <MixersSection
          title="Mixers"
          subTitle="Engaging team activities in breakout rooms."
          setIsModalVisible={setIsModalVisible}
        />

        <TemplatesSection
          handleSignIn={handleSignIn}
          handleCreateSocial={handleCreateSocial}
          setIsModalVisible={setIsModalVisible}
        />

        <Testimonials />

        {/* <PaymentSection
          handleStart14DaysTrial={handleStart14DaysTrial}
          handleCreateSocial={handleCreateSocial}
        /> */}

        <FaqSection />

        <AdvantagesSection />

        <TwitterWall title="People love thursday" />
        
        <PLGBranding />
        
        <CatchPhraseSection />
        {isJune9th() && (
          <FloatingBannerContainer
            handleOnClick={handleProductHuntDSRedirect}
            isClickable
            customClass="ph-f-banner"
          >
            <CountdownTimer
              isClickable={false}
              icon={`${process.env.ASSETS_URL}phIconRed.png`}
            />
          </FloatingBannerContainer>
        )}
      </div>

      <Footer />
    </>
  )
}

export default IndexPage
