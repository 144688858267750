export const handleProductHuntRedirect = (ev: React.MouseEvent) => {
  ev.stopPropagation()
  window.location.href =
    "https://www.producthunt.com/posts/conversations-by-thursday"
}

export const handleProductHuntDSRedirect = (ev: React.MouseEvent) => {
  ev.stopPropagation()
  window.location.href =
    "https://www.producthunt.com/posts/drunk-startups-by-thursday"
}
