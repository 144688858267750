import React from "react"

const TSLogo: React.FC<{ fillColor?: string }> = ({
  fillColor = "#ED7B2A",
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.69736 18.661H7.79436V7.964H11.6134V5.361H0.878357V7.964H4.69736V18.661Z"
        fill={fillColor}
      />
      <path
        d="M17.8397 19.022C20.4237 19.022 23.0077 17.635 23.0077 14.804C23.0077 12.733 21.6397 11.232 18.7137 10.662L17.2127 10.358C16.3197 10.168 15.7877 9.731 15.7877 8.971C15.7877 8.021 16.6427 7.394 17.7257 7.394C18.9607 7.394 19.9677 8.23 20.0817 9.617L23.1217 9.465C22.9507 6.52 20.3667 5 17.7637 5C15.2747 5 12.8047 6.425 12.8047 9.218C12.8047 11.213 14.0207 12.714 16.9277 13.284L18.4287 13.588C19.4167 13.797 19.9867 14.253 19.9867 15.013C19.9867 16.001 19.0747 16.628 17.8587 16.628C16.4147 16.628 15.2747 15.697 15.2177 14.215L12.2537 14.405C12.3487 17.445 15.0847 19.022 17.8397 19.022Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default TSLogo
