import React from "react"
import CharadesIcon from "../assets/charadesIcon"
import CoffeeIcon from "../assets/coffeeIcon"
import DoodleColorIcon from "../assets/doodleIcon"
import IceBreakerIcon from "../assets/iceBreakerIcon"
import KnowYourTeamIcon from "../assets/kytIcon"
import Speed1on1Icon from "../assets/speed1-1Icon"
import TemplatesCoffeeIcon from "../assets/templatesCoffeeIcon"
import TriviaColorIcon from "../assets/triviaIcon"
import TtalIcon from "../assets/ttalIcon"
import WindowIcon from "../assets/windowIcon"
import WineGlassIcon from "../assets/wineGlassIcon"
import WYRColorIcon from "../assets/wyrIcon"
import { AmplitudeEvents } from "../types/amplitudeEvents"
import {
  handleMouseDownEvent,
  handleMouseUpEvent,
} from "../utils/buttonClickScaleEffect"
import { ActivityEnum } from "../utils/constants"

// import InstantSocial from "../common/InstantSocial"
import "./FreeMixerModal.css"

interface FreeMixerModalProps {
  show?: boolean
  setShow?: React.Dispatch<React.SetStateAction<boolean>>
  handleSignIn?: any
  handleCreateSocial?: any
}

const getAmplitudeEvent = (templateNumber: number) => {
  let color
  switch (templateNumber) {
    case 0:
      return AmplitudeEvents.CREATE_SOCIAL_FROM_TEMPLATES_CARD1
    case 1:
      return AmplitudeEvents.CREATE_SOCIAL_FROM_TEMPLATES_CARD2
    case 2:
      return AmplitudeEvents.CREATE_SOCIAL_FROM_TEMPLATES_CARD3
    case 3:
      return AmplitudeEvents.CREATE_SOCIAL_FROM_TEMPLATES_CARD4
    default:
      return AmplitudeEvents.CREATE_SOCIAL_FROM_TEMPLATES_CARD1
  }
}

const FreeMixerModal: React.FC<FreeMixerModalProps> = ({
  show = true,
  setShow,
  handleSignIn,
  handleCreateSocial,
}) => {
  return (
    <>
      {show && (
        <>
          <div className="MainContainer" onClick={() => setShow && setShow(false)}></div>

          <div className="modals-container">
            <div className="sub-modal">
              <div className="pricing-feature-container">
                <div className="pricing-feature">
                  <div className="pricing-feature-icon-wrapper">
                    <WineGlassIcon size="24" />
                  </div>
                  <div className="pricing-feature-text-container">
                    <div className="pricing-feature-title">
                      1 Mixer per social
                    </div>
                    <div className="pricing-feature-text grey-txt">
                      You can play any mixer once on Thursday.
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={handleCreateSocial(
                  AmplitudeEvents.CREATE_FREE_SOCIAL_FROM_MODAL
                )}
                onMouseDown={handleMouseDownEvent}
                onMouseUp={handleMouseUpEvent}
                className="price-btn orange-btn white-color"
              >
                Start a free social
              </button>
            </div>
            <div className="modal-middle-text">
              You might also like our team plan!
            </div>
            <div className="sub-modal">
              <div className="pricing-feature-container">
                <div className="pricing-feature">
                  <div className="pricing-feature-icon-wrapper">
                    <WineGlassIcon size="24" />
                  </div>
                  <div className="pricing-feature-text-container">
                    <div className="pricing-feature-title">
                      Unlimited Mixers
                    </div>
                    <div className="pricing-feature-text grey-txt">
                      Doodle race, Trivia, Would you rather, 2 Truths & a lie,
                      Charades and many more.
                    </div>
                  </div>
                </div>
                <div className="pricing-feature">
                  <div className="pricing-feature-icon-wrapper">
                    <WindowIcon />
                  </div>
                  <div className="pricing-feature-text-container">
                    <div className="pricing-feature-title">
                      Social Templates
                    </div>
                    <div className="pricing-feature-text grey-txt">
                      Start a quick social with a single click. One for all
                      occasions.
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={handleSignIn(
                  AmplitudeEvents.START_14_DAY_TRIAL_FROM_MODAL
                )}
                onMouseDown={handleMouseDownEvent}
                onMouseUp={handleMouseUpEvent}
                className={"price-btn purpleBtn white-color"}
              >
                Start your 14 day trial
              </button>
              <div className="pricing-feature-text no-card-text">
                No card required, cancel anytime
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default FreeMixerModal
