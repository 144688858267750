import React from "react"
import GreenDollar from "../assets/GreenDollar"
import HomeIcon from "../assets/HomeIcon"
import BlueTemplate from "../assets/BlueTemplate"
import OrangeTemplate from "../assets/OrangeTemplate"
import TSLogo from "../assets/TrueSparrowLogos/TSLogo"
import { AmplitudeEvents } from "../types/amplitudeEvents"
import { sendAmplitudeData } from "../utils/amplitude"

const FloatingDock: React.FC<{
  setIsModalVisible: (value: React.SetStateAction<boolean>) => void
}> = ({ setIsModalVisible }) => {
  const handleScrollToScetion = (sectionId: string) => {
    const section = document.getElementById(sectionId)
    section.scrollIntoView({ behavior: "smooth" })
  }
  const [isHover, setIsHover] = React.useState(false)

  return (
    <>
      <div className="floating-dock-container">
        {isHover && (
          <div className="orange-action-button-hover-container">
            <h4 className="orange-action-button-hover-text">
              Makers of Thursday
            </h4>
          </div>
        )}
        <div
          className="action-button purple-action-button"
          onClick={() => {
            handleScrollToScetion("home-section")
          }}
        >
          <HomeIcon />
        </div>
        {/* <div
          className="action-button green-action-button"
          onClick={() => {
            handleScrollToScetion("pricing-section")
          }}
        >
          <GreenDollar />
        </div> */}
        <div
          className="action-button blue-action-button"
          onClick={() => {
            handleScrollToScetion("templates-section")
          }}
        >
          <BlueTemplate />
        </div>
        <div
          className="action-button orange-action-button"
          onClick={() => {
            handleScrollToScetion("plgbranding-section")
          }}
          onMouseEnter={() => {
            setIsHover(true)
          }}
          onMouseLeave={() => {
            setIsHover(false)
          }}
        >
          <TSLogo />
        </div>
        <div
          className="login-button"
          onClick={() => {
            if (window?.innerWidth < 800) {
              // The body scroll should be disable when the modal is open
              const body = document.querySelector("body")
              body.style.overflow = "hidden"
              sendAmplitudeData(AmplitudeEvents.SHARE_MODAL_OPENED)
              setIsModalVisible(true)
            } else {
              sendAmplitudeData(AmplitudeEvents.FLOATING_MENU_LOGIN_CLICKED)
              window.location.href = "https://go.thursday.social/sign-in"
            }
          }}
        >
          Get started
        </div>
      </div>
    </>
  )
}

export default FloatingDock
