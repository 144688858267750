import React from "react"
import CursorIconGroup from "../assets/cursorIconGroup"
import DrunkStartupIcon from "../assets/DrunkStartupsIcon"
import {
  CharadesWhiteIcon,
  DoddleIcon,
  LoungeIcon,
  WYRIcon,
} from "../assets/heroVideoOptions"
import KytIconWhite from "../assets/KytIconWhite"
import OrangeScribble from "../assets/orangeScribble"
import PeopleIcon from "../assets/peopleIcon"
import RedScribble from "../assets/redScribble"
import WineGlassIcon from "../assets/wineGlassIcon"
import {
  FeaturesVerticalContent,
  FeaturesVerticalContentType,
} from "../components/FeaturesVertical"
import TextWithWigglyLineAnimation from "../components/TextWithWigglyLineAnimation"
import HeroImage from "../images/drunkStartups/dsHeroImage.png"

export const landingPageFeatureVertical: [
  FeaturesVerticalContent,
  FeaturesVerticalContent
][] = [
  [
    {
      featureVClassName: FeaturesVerticalContentType.Details,
      header: (
        <>
          Get together with your team in{" "}
          <TextWithWigglyLineAnimation>lounge</TextWithWigglyLineAnimation>
        </>
      ),
      subtext:
        " Break out of the Zoom grid - talk, chat and have fun in the lounge. Hop onto the stage to show your pretty face!",
      btnColor: "purple",
      btnTxt: "Chill in lounge",
      handlebtn:"ToJoinPage",
      icon: <CursorIconGroup />,
    },
    {
      featureVClassName: FeaturesVerticalContentType.Banner,
      bannerSrc: process.env.ASSETS_URL + "marketingFeatureS1.png",
    },
  ],
  [
    {
      featureVClassName: FeaturesVerticalContentType.Banner,
      bannerSrc: process.env.ASSETS_URL + "marketingFeatureS2.png",
    },

    {
      featureVClassName: FeaturesVerticalContentType.Details,
      header: (
        <>
          <TextWithWigglyLineAnimation color="red">
            Mixers!
          </TextWithWigglyLineAnimation>{" "}
          &nbsp;Social activities in small groups
        </>
      ),
      subtext: `Designed for max fun and conversations. Currently on air: Drunk Startups, Doodle
        Race, Trivia, Would You Rather, 2 truths & a lie, Charades, Speed
        1-on-1, Mindful Talks, and Know Your Team.`,
      btnColor: "red",
      btnTxt: "Start a mixer",
      handlebtn:"ToJoinPage",
      icon: <WineGlassIcon />,

      floatingScribble: {
        position: "bottom",
        icon: <RedScribble />,
      },
    },
  ],
  [
    {
      featureVClassName: FeaturesVerticalContentType.Details,
      header: <> Meet different people every time</>,
      subtext: `Mixer groups are randomised for every round. Enjoy with different
        people each time you go in a mixer.`,
      btnColor: "orange",
      btnTxt: "Invite your team",
      handlebtn:"ToDashboard",
      icon: <PeopleIcon />,
      floatingScribble: {
        position: "top",
        icon: <OrangeScribble />,
      },
    },
    {
      featureVClassName: FeaturesVerticalContentType.Banner,
      bannerSrc: process.env.ASSETS_URL + "marketingFeatureS3.png",
    },
  ],
]

export const landingPageMugshotList = [
  {
    number: 1,
    color: "green",
    popover: { content: "Super designer", textColor: "white" },
  },
  {
    number: 2,
    color: "orange",
    popover: { content: "Wonder developer", textColor: "white" },
  },
  {
    number: 3,
    color: "lightBlue",
    popover: { content: "Curious researcher", textColor: "white" },
  },
  {
    number: 4,
    color: "red",
    popover: { content: "Veteran founder", textColor: "white" },
  },
]

export const landingPageHeroSectionImages = [
  {
    icon: <LoungeIcon />,
    name: "lounge",
    src: `${process.env.ASSETS_URL}lounge.png`,
  },
  {
    icon: <DrunkStartupIcon />,
    name: "drunk startups",
    src: HeroImage,
  },

  {
    icon: <KytIconWhite />,
    name: "know your team",
    src: `${process.env.ASSETS_URL}S1-1.png`,
  },
  {
    icon: <CharadesWhiteIcon />,
    name: "charades",
    src: `${process.env.ASSETS_URL}Charades.png`,
  },
  {
    icon: <DoddleIcon />,
    name: "doodle race",
    src: `${process.env.ASSETS_URL}DoodleRace.png`,
  },
  // {
  //   icon: <WYRIcon />,
  //   name: "would you rather",
  //   src: `${process.env.ASSETS_URL}WYR.png`,
  // },
]
