export const getPSTTime: any = (date: []) => {
  const now = new Date(...date)

  const localDate = now.getTime()
  const localOffset = now.getTimezoneOffset() * 60000
  const utc = localDate + localOffset

  const pst = utc + 3600000 * -7

  const pstCurrentTime = new Date(pst)

  return pstCurrentTime
}
