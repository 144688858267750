import React, { FunctionComponent } from "react"
import Marquee from "react-fast-marquee"
import "./PLGBranding.css"
import TrueSparrowLogo from "../assets/TrueSparrowLogos/TrueSparrowLogo"
import { plgproductlogos } from "../constants/plgproductlogos"

interface PLGBrandingProps {
  bgColor?: string
}

const settings = {
  pauseOnHover: true,
  speed: 40,
  gradient: false,
}

const PLGBranding: FunctionComponent<PLGBrandingProps> = ({
  bgColor = "palePink",
}) => {
  return (
    <div
      className="plgbranding-section"
      id="plgbranding-section"
      style={{ background: `var(--theme-${bgColor})` }}
    >
      <div className="plg-branding-container">
        <div className="plg-branding-grid">
          <div className="plg-heading">Thursday is made by</div>
          <div className="plg-logo">
            <TrueSparrowLogo fillColor={"white"} />{" "}
          </div>
          <div className="plg-details">
            A product studio with vast experience in web and mobile app
            development. We help companies design, develop and launch path
            breaking products.
          </div>
          <div className="marquee-wrapper">
            <Marquee {...settings}>
              <div className="plg-products">
                {plgproductlogos.map((val, ind) => (
                  <div className="plg-products-logo-container">
                    <img
                      className="image-container"
                      key={ind + val.name + "image"}
                      src={val.src}
                    />
                  </div>
                ))}
              </div>
            </Marquee>
          </div>
          <div className="plg-link-container">
            <a
              className="plg-link"
              href="https://truesparrow.com/?ref=thursday"
              target={"_blank"}
            >
              Get in touch
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PLGBranding
