import React, { FunctionComponent } from "react"

interface CursorIconGroupProps {
  color?: string
  size?: string
}

const CursorIconGroup: FunctionComponent<CursorIconGroupProps> = ({
  color = "#6633FF",
  size = "40",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.33398"
        y="1.66663"
        width="33.3333"
        height="13.3333"
        rx="6.66667"
        fill={color}
      />
      <rect
        x="21.666"
        y="20"
        width="15"
        height="15"
        rx="5.83333"
        fill={color}
      />
      <path
        d="M5 21.6666L9.86054 33.4128L11.5861 28.2977L16.6667 26.5603L5 21.6666Z"
        fill={color}
        stroke={color}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CursorIconGroup
