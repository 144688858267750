import React, { FunctionComponent } from "react"
import WigglyLines from "../assets/wigglyLine"

interface TextWithWigglyLineAnimationProps {
  color?: string
}

const TextWithWigglyLineAnimation: FunctionComponent<TextWithWigglyLineAnimationProps> =
  ({ color = "purple", children }) => {
    return (
      <span className="text-with-underline salt-txt">
        <span className="underlineAnimation-activate-onHover">{children}</span>

        <div className="underlineAnimation-container">
          <div className="wigglyLines-container">
            <WigglyLines color={`var(--theme-${color})`} />
          </div>
        </div>
      </span>
    )
  }

export default TextWithWigglyLineAnimation
