import { getPSTTime } from "./getPSTTime"

export const isBefore28thOctober = () => {
  const currentPstTime = getPSTTime([])

  if (currentPstTime.getDate() < 29 && currentPstTime.getMonth() === 9) {
    return true
  }

  return false
}

export const isJune9th = () => {
  const currentPstTime = getPSTTime([])

  // Todo: date
  if (currentPstTime.getDate() === 9 && currentPstTime.getMonth() === 5) {
    return true
  }

  return false
}

export const isDate = (date: number, month: number) => {
  const currentPstTime = getPSTTime([])

  if (currentPstTime.getDate() ===  date && currentPstTime.getMonth() === month) {
    return true
  }

  return false
}