import React, { FunctionComponent, MouseEventHandler } from "react"
import "./FloatingBannerContainer.css"

interface FloatingBannerContainerProps {
  customClass?: string
  handleOnClick?: MouseEventHandler
  isClickable?: boolean
}

const FloatingBannerContainer: FunctionComponent<FloatingBannerContainerProps> =
  ({ children, customClass, isClickable = false, handleOnClick }) => {
    return (
      <div
        className={customClass + " floating-countdown"}
        onClick={isClickable ? handleOnClick : () => {}}
        style={{
          cursor: isClickable ? "pointer" : "auto",
        }}
      >
        {children}
      </div>
    )
  }

export default FloatingBannerContainer
