import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import "./CountdownTimer.css"
import catPng from "../images/ph-cat.png"
import { getPSTTime } from "../utils/getPSTTime"
import { handleProductHuntRedirect } from "../utils/handleProducthuntRedirect"

interface CountdownTimerProps {
  icon?: string
  isClickable?: boolean
}

const CountdownTimer: FunctionComponent<CountdownTimerProps> = ({
  icon = catPng,
  isClickable = true,
}) => {
  const [time, setTime] = useState(["00", "00", "00"])
  const timeRef = useRef(["00", "00", "00"])

  const updateTime = newTime => {
    timeRef.current = newTime

    setTime(newTime)
  }

  const decrementHourAndMinutes = ([hours, minutes]) => {
    let updatedMinutes = minutes
    let updatedHours = hours

    if (updatedMinutes === 0) {
      updatedMinutes = 59
      updatedHours = updatedHours === 0 ? 23 : updatedHours - 1
    } else {
      updatedMinutes -= 1
    }

    return [updatedHours, updatedMinutes]
  }

  const normalizeTimerNumber = timerValue => {
    return timerValue.map(val => {
      let numString = `${val}`

      return `${numString.length === 2 ? numString : "0" + numString}`
    })
  }

  const decrementCountdown = () => {
    const updatedTime = [
      +timeRef.current[0],
      +timeRef.current[1],
      +timeRef.current[2],
    ]

    if (updatedTime[2] === 0) {
      updatedTime[2] = 59

      const [newHours, newMinutes] = decrementHourAndMinutes([
        updatedTime[0],
        updatedTime[1],
      ])

      updatedTime[0] = newHours
      updatedTime[1] = newMinutes
    } else {
      updatedTime[2] -= 1
    }

    updateTime(normalizeTimerNumber(updatedTime))
  }

  useEffect(() => {
    // Get Initial difference.
    const currentTime = getPSTTime([])

    const diffs = [
      `${23 - currentTime.getHours()}`,
      `${60 - currentTime.getMinutes()}`,
      `${60 - currentTime.getSeconds()}`,
    ]

    updateTime(normalizeTimerNumber(diffs))
    const countdownInterval = setInterval(() => {
      decrementCountdown()
    }, 1000)

    return () => {
      clearInterval(countdownInterval)
    }
  }, [])

  return (
    <div className="timer-container">
      <div className="left">
        <div className="liveNow-greenLight"></div> Live now on &nbsp;
        <img
          onClick={isClickable ? handleProductHuntRedirect : () => {}}
          height="30"
          style={{
            cursor: isClickable ? "pointer" : "auto",
          }}
          src={icon || catPng}
        />
      </div>

      <div className="right">
        <span className="live-txt ">TIME LEFT</span>
        <div className="time-block">{`${time[0]}`}</div>:
        <div className="time-block">{`${time[1]}`}</div>:
        <div className="time-block">{`${time[2]}`}</div>
      </div>
    </div>
  )
}

export default CountdownTimer
