
export const plgproductlogos = [
    {
      name: "Fab",
      src: `${process.env.ASSETS_URL}plgproductlogos/Fab.svg`,
    },
    {
        name: "Hem",
        src: `${process.env.ASSETS_URL}plgproductlogos/Hem.svg`,
      },
      {
        name: "Moxie",
        src: `${process.env.ASSETS_URL}plgproductlogos/Moxie.svg`,
      },
      {
        name: "Pepo",
        src: `${process.env.ASSETS_URL}plgproductlogos/Pepo.svg`,
      },
      {
        name: "Slackmin",
        src: `${process.env.ASSETS_URL}plgproductlogos/Slackmin.svg`,
      },
      {
        name: "Listner",
        src: `${process.env.ASSETS_URL}plgproductlogos/listener+icon+mono.svg`,
      },
      {
        name: "ost",
        src: `${process.env.ASSETS_URL}plgproductlogos/ost.svg`,
      },
      {
        name: "thursday",
        src: `${process.env.ASSETS_URL}plgproductlogos/thursday+logo+mono.svg`,
      },
  ]